import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import ModalImage from "./ModalImage";
import Header from "./Header";
import Logo from "../assets/kinera_.webp";
import KineContext from "../context/KineContext";
import Button from "./Button";
import { Loading } from "./Loading";
import { Link } from "react-router-dom";
import { uploadImageToUserFolder } from "../services/firebase";
import { getUserFiles } from "../services/userStorage";

const LastImages = ({ refresh }) => {
  const { loggeduser } = React.useContext(KineContext);
  const [images, setImages] = useState([]);
  const [modal, setModal] = useState(false);
  const [inFetch, setInFetch] = useState(false);
  const [inSaved, setInSaved] = useState(false);
  const [verifyImageStatement, setVerifyImageStatement] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const serverAddress = "https://ia-tool.kinera.network";

  const handleShowModal = (image) => {
    setModal(true);
    setIsPrivate(false);
    setSelectedImage(image);
    getCurrentImageStatement(image);
  };

  const fetchImages = async () => {
    if (!loggeduser || !loggeduser.address) {
      console.log("No clientId found. No images to fetch.");
      return;
    }
    setInFetch(true);

    try {
      const historyResponse = await fetch(
        `${serverAddress}/history?clientId=${loggeduser.address}`
      );
      const history = await historyResponse.json();

      const imageListPromises = [];

      Object.keys(history).forEach(async (promptId) => {
        const promptHistory = history[promptId];

        // Filter by client_id
        const clientId = promptHistory?.prompt?.[3]?.client_id;
        if (clientId !== loggeduser.address) {
          return; // Skip this prompt if the client_id doesn't match the logged user
        }

        if (promptHistory?.outputs) {
          for (const nodeId in promptHistory.outputs) {
            const nodeOutput = promptHistory.outputs[nodeId];

            const getImageData = async (image) => {
              const imageUrl = await fetch(
                `${serverAddress}/view?filename=${image.filename}&subfolder=${
                  image.subfolder
                }&type=${image.type}&rand=${Math.random()}`
              )
                .then((response) => response.blob())
                .then((blob) => URL.createObjectURL(blob));

              const imageFile = await fetch(
                `${serverAddress}/view?filename=${image.filename}&subfolder=${
                  image.subfolder
                }&type=${image.type}&rand=${Math.random()}`
              ).then((response) => response.blob());

              // Extracting prompt text, width, height, and steps
              let promptText = "";
              let width = 512;
              let height = 512;
              let steps = 10;

              for (const key in promptHistory?.prompt?.[2]) {
                const node = promptHistory.prompt[2][key];
                if (node?.inputs?.text) {
                  promptText = node.inputs.text;
                  break;
                }
              }

              if (promptHistory?.prompt?.[2]?.[5]?.inputs) {
                width = promptHistory.prompt[2][5].inputs.width || 512;
                height = promptHistory.prompt[2][5].inputs.height || 512;
              }

              if (promptHistory?.prompt?.[7]?.inputs) {
                steps = promptHistory.prompt[7].inputs.steps || 10;
              } else if (promptHistory?.prompt?.[3]?.inputs) {
                steps = promptHistory.prompt[3].inputs.steps || 10;
              }

              // Determining the model
              const model =
                image.format === "image/gif" && image.subfolder
                  ? image.subfolder
                  : "dreamshaper_8";

              return {
                url: imageUrl,
                file: imageFile,
                text: promptText,
                filename: image.filename,
                width: width,
                height: height,
                steps: steps,
                model: model,
                format: image.format
              };
            };

            if (nodeOutput?.images) {
              const imagePromises = nodeOutput.images.map(getImageData);
              imageListPromises.push(...imagePromises);
            }

            if (nodeOutput?.gifs) {
              const gifPromises = nodeOutput.gifs.map(getImageData);
              imageListPromises.push(...gifPromises);
            }
          }
        }
      });

      const imageList = await Promise.all(imageListPromises);
      setImages(imageList);
      setInFetch(false);
    } catch (error) {
      setInFetch(false);
      console.error("Error fetching history:", error);
    }
  };

  const downloadImage = () => {
    if (selectedImage && selectedImage.url) {
      console.log("selectedImage", selectedImage);
      let extension;
      if (!selectedImage.format) {
        extension = "png";
      } else {
        extension = selectedImage.format.split("/")[1];
      }
      const link = document.createElement("a");
      link.href = selectedImage.url;
      link.download = `generated-image.${extension}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const saveImage = async (param) => {
    setInSaved(true);
    if (!selectedImage) {
      alert("No image selected.");
      return;
    }

    if (!loggeduser || !loggeduser.address) {
      alert("User not logged in.");
      return;
    }

    let req = await uploadImageToUserFolder(selectedImage, loggeduser.address);
    if (req && req.image_path) {
      setIsPrivate(true);
    }
    setInSaved(false);
  };

  const closeModal = () => {
    setModal(false);
  };

  const getCurrentImageStatement = async (param) => {
    setVerifyImageStatement(true);
    let req = await getUserFiles(param, loggeduser.address);
    if (req && req.image_path) {
      setIsPrivate(true);
    }
    setVerifyImageStatement(false);
  };

  useEffect(() => {
    if (loggeduser) {
      fetchImages();
    }
  }, [loggeduser, refresh]);

  return (
    <div className="flex flex-col">
      {modal && (
        <ModalImage onClose={closeModal}>
          <div className="flex container-modal justify-center w-full h-full border-color side-color">
            <div className="w-[50%] container-modal relative flex flex-col items-center justify-center">
              <img
                alt="current image"
                className="w-full right-modal absolute opacity-60 z-[20] top-0 h-full object-cover blur-xl"
                src={selectedImage.url}
              />
              <img
                alt="current image"
                className="relative z-[100] object-contain"
                src={selectedImage.url}
              />
            </div>
            <div className="w-[50%] left-modal bg-white justify-between flex flex-col p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <img src={Logo} width={"30px"} alt="Logo" />
                </div>
                <span
                  onClick={() => {
                    setModal(false);
                  }}
                  className="material-symbols-outlined mr-4 text-black hover:opacity-70 hover:cursor-pointer"
                >
                  close
                </span>
              </div>
              <div className="flex flex-col gap-4">
                <div className="">
                  <div className="flex items-center gap-2">
                    <span className="material-symbols-outlined text-sm">
                      forum
                    </span>
                    <p className="label text-sm">Prompt</p>
                  </div>
                  <p className="text-2xl text">{selectedImage.text}</p>
                </div>
                <div className="w-full flex flex-wrap wrap gap-2">
                  <div className="card-info text-container grow">
                    <div className="flex items-center gap-2">
                      <span className="material-symbols-outlined text-sm text-gray-400">
                        draw
                      </span>
                      <p className="label text-sm">Model</p>
                    </div>
                    <div className="flex text items-center gap-1 text-center justify-center text-white">
                      {selectedImage.model || "N/A"}
                    </div>
                  </div>

                  <div className="card-info text-container ">
                    <div className="flex items-center gap-2">
                      <span className="material-symbols-outlined text-sm text-gray-400">
                        crop
                      </span>
                      <p className="label text-sm">Dimensions</p>
                    </div>
                    <div className="flex items-center justify-center gap-1">
                      <p className="text-white text">
                        {selectedImage.width || "N/A"}
                      </p>
                      <span className="text-white text">x</span>
                      <p className="text-white text">
                        {selectedImage.height || "N/A"}
                      </p>
                    </div>
                  </div>
                  <div className="card-info text-container grow">
                    <div className="flex items-center gap-2">
                      <span className="material-symbols-outlined text-sm text-gray-400">
                        floor
                      </span>
                      <p className="label text-sm">Steps</p>
                    </div>
                    <div className="text flex items-center gap-1 text-center justify-center text-white">
                      {selectedImage.steps || "N/A"}
                    </div>
                  </div>
                  <div className="card-info text-container ">
                    <div className="flex items-center gap-2">
                      <span className="material-symbols-outlined text-sm text-gray-400">
                        schedule
                      </span>
                      <p className="label text-sm">Created</p>
                    </div>
                    <div className="text flex items-center gap-1 text-center justify-center text-white">
                      N/A
                    </div>
                  </div>
                </div>
                {verifyImageStatement ? (
                  <div className="flex items-end justify-end w-full">
                    <Loading />
                  </div>
                ) : (
                  <div className="flex items-center gap-2 justify-end">
                    <Button value="Cancel" type="subtle" onClick={closeModal} />
                    <Button
                      value="Download"
                      type="primary"
                      onClick={downloadImage}
                    />

                    {inSaved ? (
                      <Loading />
                    ) : !isPrivate ? (
                      <Button
                        value="Save"
                        type="primary"
                        onClick={() => {
                          saveImage(selectedImage);
                        }}
                      />
                    ) : (
                      "You have already saved this image"
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalImage>
      )}
      <p className="sub-text text-sm mt-2 w-full items-center gap-1 opacity-40 flex text-white font-bold">
        <span className="material-symbols-outlined text-lg">history</span>
        History
      </p>
      <div className="flex flex-col gap-1">
        <span className="caption-text">
          Your image history is saved for 1 hour
        </span>
        <span className="caption-text">
          If you want to save your image, you can publish it on your{" "}
          <Link
            to={"https://alpha.kinera.network/"}
            target="_blank"
            className="ml-1 mr-1 underline text-blue-300"
          >
            Kinera social network
          </Link>{" "}
          or download it
        </span>
      </div>
      {inFetch ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <div className="flex flex-wrap gap-2 mt-4 justify-center">
          {images.length > 0 ? (
            images.map((image, index) => (
              <div className="w-[65px] h-[65px]">
                <img
                  onClick={() => {
                    handleShowModal(image);
                  }}
                  key={index}
                  src={image.url}
                  alt={`Generated ${index}`}
                  className="last-images object-cover rounded hover:opacity-70 hover:cursor-pointer"
                />
              </div>
            ))
          ) : (
            <p className="text-white">No images found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default LastImages;

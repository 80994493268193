import React, { useState, useEffect } from "react";
import Modal from "../components/Modal";
import Dropdown from "../components/Dropdown";
import Input from "../components/Input";
import { Loading } from "../components/Loading";
import { web3Accounts, web3Enable } from "@polkadot/extension-dapp";
import { login } from "../services/login";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
const Login = ({ setShowModal, showModal, isLoginFetch, setIsLoginFetch }) => {
  const [selectedOption, setSelectedOption] = useState([]);
  const [accounts, setAccounts] = useState(false);
  const [user, setUser] = useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [password, setPassword] = useState("");

  const handleSelectAccount = (account) => {
    setSelectedOption(account);
  };

  const onClose = () => {
    setShowModal(false);
  };

  const extensionSetup = async () => {
    // console.log("entrei");
    await web3Enable("kinera-stream");
    const accounts = await web3Accounts();
    // console.log("accounts", accounts);
    let formated = accounts.map((account) => {
      return {
        label: account.meta.name,
        value: account.address
      };
    });

    setAccounts(formated);
  };

  useEffect(() => {
    extensionSetup();

    // eslint-disable-next-line react-hooks/rules-of-hooks
  }, []);

  const handleLogin = async () => {
    try {
      setIsLoginFetch(true);
      const req = await login(selectedOption.value, password);

      if (req && req.data && req.data.error) {
        setErrorMessage(req.data.message);
        setPassword("");
        Cookies.remove("kinera-cookie");
        setIsLoginFetch(false);
        return false;
      } else if (req && req.data && !req.data.message) {
        setUser(selectedOption);
        setSelectedOption(req.data);
        setShowModal(false);
      }

      setIsLoginFetch(false);
    } catch (error) {
      console.log("deu erro", error);
    }
  };

  return (
    <div className="absolute w-full h-full">
      <Modal
        title="Login"
        onClose={onClose}
        onCancel={onClose}
        onConfirm={handleLogin}
        onConfirmLabel={"Login"}
        size="medium"
        fullScreen={true}
        disabledOnConfirm={!selectedOption || !password || isLoginFetch}
      >
        <div className="flex flex-col gap-4">
          <p className="mono w-[90%]">Select your account to login</p>
          <div className="relative flex flex-col gap-4">
            <Dropdown setSelect={handleSelectAccount} options={accounts} />
            {selectedOption && (
              <Input
                type="password"
                placeholder="Write your password"
                onChange={setPassword}
                value={password}
              />
            )}
          </div>
          <div className="flex items-center justify-center hover:opacity-70">
            <Link
              to="https://alpha.kinera.network/register-profile"
              target="_blank"
            >
              Register
            </Link>
          </div>
        </div>
        {isLoginFetch && <Loading />}
        {errorMessage && <p className="mono">{errorMessage}</p>}
      </Modal>
    </div>
  );
};

export default Login;

import Button from "./Button";
import React, { useContext, useEffect, useState } from "react";
import Icon from "../assets/icons.svg";
import { useNavigate } from "react-router-dom";
import { Loading } from "./Loading";
import { getUserData } from "../services/profile";
import KineContext from "../context/KineContext";
import { logout } from "../services/login";
import Cookies from "js-cookie";

function Header({ openModal, isLoginFetch }) {
  const navigate = useNavigate();
  const { updateUserLogin, setUser } = useContext(KineContext);
  const [fetch, setFetch] = useState(false);
  const [userData, setUserData] = useState([]);

  const getSessionData = async () => {
    let sessionData = Cookies.get("kinera-cookie");
    let req = sessionData ? JSON.parse(sessionData) : null;
    if (req) {
      setFetch(true);
      let getProfile = await getUserData(req.address);
      if (getProfile && getProfile.name) {
        setUserData(getProfile);
        setUser(getProfile);
        setFetch(false);
        updateUserLogin(getProfile);
      }
    }
  };

  const logoutUser = async () => {
    await logout();
    Cookies.remove("kinera-cookie");
    updateUserLogin(null);
    setUserData(null);
  };

  useEffect(() => {
    getSessionData();
  }, [isLoginFetch]);

  return (
    <header className="black-header z-[99999] flex justify-between">
      <img className="logo" src={Icon} alt="logo" width="30px" />
      {!userData || !userData.name ? (
        <div className="flex items-center gap-2">
          {!fetch ? (
            <Button type="primary" value="Login" onClick={openModal}></Button>
          ) : (
            <Loading />
          )}
        </div>
      ) : (
        <div className="flex items-center gap-4">
          <p className="mono text-white">{userData.name}</p>
          <Button type="primary" value="Logout" onClick={logoutUser}></Button>
        </div>
      )}
    </header>
  );
}

export default Header;

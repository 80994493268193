let moviePayload = {
  client_id: "2e5ce4e7a108421dba9f7b6cc64a74df",
  prompt: {
    2: {
      inputs: {
        vae_name: "vae-ft-mse-840000-ema-pruned.safetensors"
      },
      class_type: "VAELoader",
      _meta: {
        title: "Load VAE"
      }
    },
    3: {
      inputs: {
        text: "1girl, solo, cherry blossom, hanami, pink flower, white flower, spring season, wisteria, petals, flower, plum blossoms, outdoors, falling petals, black eyes, upper body, from side",
        clip: ["4", 0]
      },
      class_type: "CLIPTextEncode",
      _meta: {
        title: "CLIP Text Encode (Prompt)"
      }
    },
    4: {
      inputs: {
        stop_at_clip_layer: -2,
        clip: ["32", 1]
      },
      class_type: "CLIPSetLastLayer",
      _meta: {
        title: "CLIP Set Last Layer"
      }
    },
    6: {
      inputs: {
        text: "(worst quality, low quality: 1.4)",
        clip: ["4", 0]
      },
      class_type: "CLIPTextEncode",
      _meta: {
        title: "CLIP Text Encode (Prompt)"
      }
    },
    7: {
      inputs: {
        seed: 1117201569831433,
        steps: 10,
        cfg: 8,
        sampler_name: "euler",
        scheduler: "normal",
        denoise: 1,
        model: ["36", 0],
        positive: ["3", 0],
        negative: ["6", 0],
        latent_image: ["9", 0]
      },
      class_type: "KSampler",
      _meta: {
        title: "KSampler"
      }
    },
    9: {
      inputs: {
        width: 512,
        height: 512,
        batch_size: 16
      },
      class_type: "EmptyLatentImage",
      _meta: {
        title: "Empty Latent Image"
      }
    },
    10: {
      inputs: {
        samples: ["7", 0],
        vae: ["2", 0]
      },
      class_type: "VAEDecode",
      _meta: {
        title: "VAE Decode"
      }
    },
    32: {
      inputs: {
        ckpt_name: "dreamshaper_8.safetensors"
      },
      class_type: "CheckpointLoaderSimple",
      _meta: {
        title: "Load Checkpoint"
      }
    },
    35: {
      inputs: {
        frame_rate: 8,
        loop_count: 0,
        filename_prefix: "AnimateDiff/readme",
        format: "image/gif",
        pingpong: false,
        save_output: true,
        images: ["10", 0]
      },
      class_type: "VHS_VideoCombine",
      _meta: {
        title: "Video Combine 🎥🅥🅗🅢"
      }
    },
    36: {
      inputs: {
        model_name: "mm_sd_v14.ckpt",
        beta_schedule: "autoselect",
        model: ["32", 0]
      },
      class_type: "ADE_AnimateDiffLoaderGen1",
      _meta: {
        title: "AnimateDiff Loader 🎭🅐🅓①"
      }
    }
  }
};

export default moviePayload;

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Link
} from "react-router-dom";

import GenerateMovie from "./pages/movie";
import GenerateImage from "./pages/image";
import "./globalStyles/style.scss";
import Tabs from "./components/Tab";
import Sidebar from "./components/Sidebar";
import Login from "./pages/login";
import Header from "./components/Header";

const App = () => {
  const [selectedTab, setSelectedTab] = useState("image");
  const [refresh, setRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoginFetch, setIsLoginFetch] = useState(false);
  const navigate = useNavigate();

  const openModal = () => {
    setShowModal(true);
  };

  const handleTabChange = (param) => {
    setSelectedTab(param);
  };

  useEffect(() => {
    if (selectedTab === "image") {
      navigate("/image");
    } else if (selectedTab === "movie") {
      navigate("/movie");
    }
  }, [selectedTab, navigate]);

  return (
    <div className="flex w-full h-full flex-col">
      {showModal && (
        <Login
          setShowModal={setShowModal}
          showModal={showModal}
          isLoginFetch={isLoginFetch}
          setIsLoginFetch={setIsLoginFetch}
        />
      )}
      <Header openModal={openModal} isLoginFetch={isLoginFetch} />
      <div className="flex w-full h-full">
        <Sidebar
          refresh={refresh}
          openModal={openModal}
          isLoginFetch={isLoginFetch}
          selectedTab={selectedTab}
        />
        <div className="container-create flex-col w-full">
          {/**
           *   <Accounts />
           */}
          <p className="text-white">
            View more Kinera tools in:
            <b className="text-blue-300 hover:text-blue-400 ml-2">
              <Link
                to="https://alpha.kinera.network/custom-tools"
                target="_blank"
              >
                Kinera Produtions tools
              </Link>
            </b>
          </p>
          <Tabs tabChange={handleTabChange} selectedTab={selectedTab} />
          <Routes>
            <Route
              path="/image"
              element={
                <GenerateImage
                  tabChange={handleTabChange}
                  selectedTab={selectedTab}
                  setRefresh={setRefresh}
                />
              }
            />
            <Route
              path="/movie"
              element={
                <GenerateMovie
                  setRefresh={setRefresh}
                  tabChange={handleTabChange}
                  selectedTab={selectedTab}
                />
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;

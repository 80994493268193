// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { deleteObject, getStorage } from "firebase/storage";
import Cookies from "js-cookie";
import { ref, listAll, getMetadata } from "firebase/storage";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
import API from "./api";
import { insertFileInDB, removeUserFile } from "./userStorage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_BUCKET,
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_APP_MEASSUREMENT
};

export const getFolderSizeInKB = async (userAddress) => {
  const folderRef = ref(storage, userAddress);
  const fileList = await listAll(folderRef);
  let totalSize = 0;

  for (const itemRef of fileList.items) {
    const metadata = await getMetadata(itemRef);
    totalSize += metadata.size;
  }

  return (totalSize / 1024).toFixed(2); // Retorna o tamanho total em KB, arredondado para 2 casas decimais
};

export const getFolderSize = async (userAddress) => {
  const folderRef = ref(storage, userAddress);
  const fileList = await listAll(folderRef);
  let totalSize = 0;

  for (const itemRef of fileList.items) {
    const metadata = await getMetadata(itemRef);
    totalSize += metadata.size;
  }

  return totalSize;
};

export const uploadImageToUserFolder = async (file, userAddress) => {
  const userCookie = Cookies.get("kinera-cookie");

  if (userCookie) {
    const parsedUser = JSON.parse(userCookie);

    if (parsedUser.address !== userAddress) {
      return false;
    }

    const folderSize = await getFolderSize(userAddress);
    const maxStorageLimit = 2 * 1024 * 1024; // 2MB em bytes

    if (folderSize + file.file.size > maxStorageLimit) {
      console.log("err");
      let error = {
        error: true,
        message: "Exceeds maximum storage limit of 2MB."
      };
      return error;
    }

    const storageRef = ref(storage, `${userAddress}/${file.filename}`);
    const uploadTask = uploadBytesResumable(storageRef, file.file);

    try {
      const snapshot = await uploadTask;
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Insere o arquivo no banco de dados junto com a URL de download
      const req = await insertFileInDB(file, userAddress, downloadURL);

      if (req) {
        return req;
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      return { error: true, message: "File upload failed." };
    }
  } else {
    return { error: true, message: "Unauthorized" };
  }
};

export const deleteImageFromUserFolder = async (selectedImage, user) => {
  if (!selectedImage) {
    alert("No image selected.");
    return;
  }

  if (!user || !user.address) {
    alert("User not logged in.");
    return;
  }

  // Cria uma referência ao arquivo que você quer deletar
  const storageRef = ref(storage, `${user.address}/${selectedImage.filename}`);

  // Tenta deletar o arquivo
  try {
    await removeUserFile(selectedImage.filename, user.address);
    await deleteObject(storageRef);

    alert("Image deleted successfully.");
    return true;
  } catch (error) {
    alert(`Failed to delete image: ${error.message}`);
    return true;
  }
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);

import React, { useEffect, useRef, useState } from "react";

const Dropdown = ({ options, setSelect }) => {
  const [selectedOption, setSelectedOption] = useState([]);
  const [showWallets, setShowWallets] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelectAccount = (account) => {
    setSelectedOption(account);
    setSelect(account);
    setShowWallets(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowWallets(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedOption([]);
    setSelect([]);
  }, []);

  return (
    <div className="flex flex-col w-full" ref={dropdownRef}>
      <div
        className="border-default p-2"
        onClick={() => setShowWallets(!showWallets)}
      >
        {selectedOption && selectedOption.label ? (
          <p className="mono">{selectedOption.label}</p>
        ) : (
          <p className="mono">Select your wallet</p>
        )}
      </div>
      {showWallets && (
        <div className="relative z-10">
          <div className="absolute mt-2 p-2 border-default w-full bg-white rounded-[4px]">
            {options &&
              options.length &&
              options.map((val, key) => (
                <div
                  onClick={() => handleSelectAccount(val)}
                  className="p-2 hover:opacity-90 cursor-pointer hover:bg-gray-100"
                  key={key}
                >
                  <p className="mono">{val.label}</p>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;

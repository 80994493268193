import React from "react";

const Modal = ({ onClose, children }) => {
  const handleOverlayClick = (e) => {
    // Check if the click is on the overlay and not inside the modal content
    if (e.target.classList.contains("overlay")) {
      onClose();
    }
  };

  return (
    <div className="overlay" onClick={handleOverlayClick}>
      <div className="modal modal-container">{children}</div>
    </div>
  );
};

export default Modal;

import React from "react";
import Button from "./Button";

const Modal = ({
  title,
  onClose,
  size,
  onConfirm,
  onConfirmLabel,
  children,
  fullScreen,
  disabledOnConfirm
}) => {
  return (
    <div className="w-full h-full flex items-center justify-center overlay">
      {fullScreen && <div className="back-drop"></div>}
      <div
        className={`flex flex-col container modal-${size} modal gap-4 relative z-10 drop-shadow-2xl`}
      >
        <div className="w-full flex items-center justify-between">
          <p className="title">{title}</p>
          <div onClick={onClose}>
            <span className="material-symbols-outlined hover:opacity-70 cursor-pointer">
              close
            </span>
          </div>
        </div>
        {children}
        <div className="w-full items-end flex justify-end gap-4">
          <Button value="Cancel" type="default" onClick={onClose} />
          {onConfirm && (
            <Button
              value={onConfirmLabel}
              type="primary"
              onClick={onConfirm}
              disabled={disabledOnConfirm}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;

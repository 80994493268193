import React from "react";

const Tabs = ({ tabChange, selectedTab }) => {
  return (
    <div className="text-sm font-medium text-center">
      <ul className="flex flex-wrap m-8">
        <li className="me-2">
          <a
            onClick={() => {
              tabChange("image");
            }}
            className={
              selectedTab === "image"
                ? "inline-block p-4 text-white border-b-2 border-blue-600 rounded-t-lg active cursor-pointer"
                : "inline-block p-4  rounded-t-lg active text-gray-400 hover:text-white cursor-pointer"
            }
          >
            Generate Image
          </a>
        </li>
        <li className="me-2">
          <a
            onClick={() => {
              tabChange("movie");
            }}
            className={
              selectedTab === "movie"
                ? "inline-block p-4 text-white border-b-2 border-blue-600 rounded-t-lg active cursor-pointer"
                : "inline-block p-4  rounded-t-lg text-gray-400 active hover:text-gray-300 cursor-pointer"
            }
          >
            Generate Movie
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Tabs;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import payload from '../payload';
import payloadMovie from '../moviePayload';
import '../App.css'
import KineContext from "../context/KineContext";
import { uploadImageToUserFolder } from "../services/firebase";

const GenerateMovie = ({ tabChange, setRefresh }) => {
  const { loggeduser } = React.useContext(KineContext);
  const [currentData, setCurrentData] = useState(null);
  const [gifData, setGifData] = useState(null);
  const [inSave, setInSave] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [promptId, setPromptId] = useState(null);
  const [input, setInput] = useState("");
  const [changed, setChanged] = useState(false);
  const serverAddress = "https://ia-tool.kinera.network";

  useEffect(() => {
    if (loggeduser && loggeduser.address) {
      const ws = new WebSocket(
        `wss://ia.kinera.network/wss?clientId=${loggeduser.address}`
      );
      ws.onmessage = async (event) => {
        try {
          const message = JSON.parse(event.data);
          //console.log("message", event);

          if (
            message.type === "status" &&
            message.data?.status?.exec_info?.queue_remaining === 0
          ) {
            const urlParams = new URLSearchParams(
              event.currentTarget.url.split("?")[1]
            );

            verifyHistory(promptId);
          }
        } catch (error) {
          console.error("Error handling message:", error);
        }
      };
    }

    tabChange("movie");
  }, [loggeduser, promptId, serverAddress]);

  const verifyHistory = async (promptId) => {
    if (promptId) {
      setRefresh(false);
      const interval = setInterval(async () => {
        try {
          const historyResponse = await fetch(
            `${serverAddress}/history/${promptId}`
          );
          const history = await historyResponse.json();
          const promptHistory = history?.[promptId];

          if (promptHistory?.outputs) {
            for (const nodeId in promptHistory.outputs) {
              const nodeOutput = promptHistory.outputs[nodeId];

              if (nodeOutput?.gifs) {
                const gifs = await Promise.all(
                  nodeOutput.gifs.map(async (image) => {
                    const gifUrl = await fetch(
                      `${serverAddress}/view?filename=${
                        image.filename
                      }&subfolder=${image.subfolder}&type=${
                        image.type
                      }&rand=${Math.random()}`
                    )
                      .then((response) => response.blob())
                      .then((blob) => URL.createObjectURL(blob));

                    const gifFile = await fetch(
                      `${serverAddress}/view?filename=${
                        image.filename
                      }&subfolder=${image.subfolder}&type=${
                        image.type
                      }&rand=${Math.random()}`
                    ).then((response) => response.blob());

                    // Extrair informações como promptText, width, height, steps
                    let promptText = "";
                    let width = 512;
                    let height = 512;
                    let steps = 10;

                    for (const key in promptHistory?.prompt?.[2]) {
                      const node = promptHistory.prompt[2][key];
                      if (node?.inputs?.text) {
                        promptText = node.inputs.text;
                        break;
                      }
                    }

                    if (promptHistory?.prompt?.[2]?.[5]?.inputs) {
                      width = promptHistory.prompt[2][5].inputs.width || 512;
                      height = promptHistory.prompt[2][5].inputs.height || 512;
                    }

                    if (promptHistory?.prompt?.[7]?.inputs) {
                      steps = promptHistory.prompt[7].inputs.steps || 10;
                    } else if (promptHistory?.prompt?.[3]?.inputs) {
                      steps = promptHistory.prompt[3].inputs.steps || 10;
                    }

                    const model =
                      image.format === "image/gif" && image.subfolder
                        ? image.subfolder
                        : "default_model";

                    setCurrentData({
                      url: gifUrl,
                      file: gifFile,
                      text: promptText,
                      filename: image.filename,
                      width: width,
                      height: height,
                      steps: steps,
                      model: model,
                      format: image.format
                    });
                  })
                );

                setGifData(gifs[0]); // Exibe o primeiro GIF encontrado
                setRefresh(true);
                clearInterval(interval);
                setChanged(false);
                break;
              }
            }
          }
        } catch (error) {
          console.error("Error fetching history:", error);
        }
      }, 2000); // Verifica a cada 2 segundos

      return () => clearInterval(interval);
    }
  };

  const handlePromptSubmit = async (type) => {
    try {
      setChanged(true);

      let formatedPayload;
      formatedPayload = payloadMovie;
      formatedPayload.client_id = loggeduser.address;
      formatedPayload.prompt["3"].inputs.text = input;
      const myHeaders = new Headers();
      const raw = JSON.stringify(formatedPayload);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      axios.defaults.withCredentials = true;

      const response = await fetch(`${serverAddress}/prompt`, requestOptions);
      const result = await response.json();
      if (result && result.prompt_id) {
        setPromptId(result.prompt_id);
        verifyHistory(result.prompt_id);
      }
    } catch (error) {
      console.error("Error submitting prompt:", error);
    }
  };

  function generateSimpleUUID() {
    return "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const downloadImage = () => {
    if (gifData) {
      const link = document.createElement("a");
      link.href = gifData;
      link.download = "generated-image.gif"; // O nome do arquivo a ser salvo
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const saveImage = async () => {
    if (currentData) {
      setIsSave(true);
      if (!loggeduser || !loggeduser.address) {
        alert("User not logged in.");
        return;
      }

      let req = await uploadImageToUserFolder(currentData, loggeduser.address);
      console.log(req);
      if (req.error) {
        alert(req.message);
      }
      setIsSave(false);
    }
  };

  return (
    <div className="flex-col">
      <div className="flex items-end flex-col gap-4">
        <div className="input-container flex items-center gap-4 justify-between w-fit">
          <textarea
            value={input}
            className="p-2 text-white "
            placeholder="Write your prompt.."
            onChange={(e) => {
              setInput(e.target.value);
            }}
          />
        </div>
        <div>
          {loggeduser && loggeduser.address ? (
            <>
              {!changed && (
                <button
                  disabled={!input || !loggeduser || !loggeduser.address}
                  className="primary-button"
                  onClick={() => {
                    handlePromptSubmit(false);
                  }}
                >
                  Generate
                </button>
              )}
            </>
          ) : (
            <p className="text-white">Login to create new artwork</p>
          )}
        </div>
      </div>
      {changed && (
        <div className="mt-10 flex items-center justify-center  h-48 bg-gray-300 rounded  image-loading">
          <svg
            className="w-10 h-10 text-gray-200 dark:text-gray-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
          </svg>
          <p className="absolute text-[#000] font-bold">Loading...</p>
        </div>
      )}
      <div className="flex items-center flex-wrap mt-4 justify-center">
        {currentData && !changed && (
          <div className="flex items-end gap-4 flex-col">
            <img
              className="flex items-center img-render"
              src={currentData.url}
              alt="Generated"
            />
            <div className="flex items-baseline gap-2">
              <button
                disabled={!input}
                className="save-button"
                onClick={downloadImage}
              >
                Download
              </button>
              <div className="flex flex-col gap-1">
                <button
                  disabled={!input}
                  className="save-button w-fill-available text-center justify-center flex"
                  onClick={saveImage}
                >
                  Save
                </button>
                <span className="text-caption">
                  When saving the file it will be saved in "my documents" in
                  Kinera
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GenerateMovie;

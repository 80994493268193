import React, { useState, useEffect } from "react";
import Header from "./Header";
import LastImages from "./LastImages";
import KineContext from "../context/KineContext";
import moviePayload from "../moviePayload";
import payload from "../payload";

const Sidebar = ({ refresh, openModal, isLoginFetch, selectedTab }) => {
  const { user } = React.useContext(KineContext);
  const [width, setWidth] = useState(512);
  const [height, setHeight] = useState(512);
  const [steps, setSteps] = useState(15);

  useEffect(() => {
    if (selectedTab === "movie") {
      moviePayload.prompt[7].inputs.steps = steps;
      moviePayload.prompt[9].inputs.width = width;
      moviePayload.prompt[9].inputs.height = height;
    } else if (selectedTab === "image") {
      payload.prompt[3].inputs.steps = steps;
      payload.prompt[5].inputs.width = width;
      payload.prompt[5].inputs.height = height;
    }
  }, [width, height, steps, selectedTab]);

  return (
    <div className="flex items-center flex-col justify-between sidebar p-2">
      <div className="w-full">
        <LastImages refresh={refresh} />
      </div>
      <div className="options flex flex-col gap-4 w-full">
        <div className="mt-2">
          <p className="text-sm mt-2 w-full items-center gap-1 opacity-40 flex text-white font-bold">
            <span className="material-symbols-outlined text-lg">settings</span>
            Engine
          </p>
          <div className="engine flex flex-col items-center mt-2">
            <p className="flex items-center gap-2 title text-2xl text-white">
              <img
                src="https://storage.googleapis.com/comfy-assets/logo.png"
                width={"30px"}
                alt="Comfy Logo"
              />
              Comfy
            </p>
            <div className="flex items-center text-white gap-2">
              <p className="text-white text-sm">Animated diff</p>
              <span className="material-symbols-outlined">movie</span>
            </div>
            <div className="flex items-center text-white gap-2">
              <p className="text-white text-sm">Dream shapper</p>
              <span className="material-symbols-outlined">image</span>
            </div>
          </div>
        </div>

        <div className="option-item flex flex-col gap-1 text-gray-400 w-full">
          <div className="flex items-center gap-2">
            <span className="material-symbols-outlined">width</span>
            <label>Width</label>
          </div>
          <input
            type="range"
            min="1"
            max="512"
            value={width}
            onChange={(e) => setWidth(e.target.value)}
          />
          <span className="w-full text-center">{width}</span>
        </div>

        <div className="option-item flex flex-col gap-1 text-gray-400">
          <div className="flex items-center gap-2">
            <span className="material-symbols-outlined text-lg">height</span>
            <label>Height</label>
          </div>
          <input
            type="range"
            min="1"
            max="512"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
          <span className="w-full text-center">{height}</span>
        </div>

        <div className="option-item flex flex-col gap-1 text-gray-400">
          <div className="flex items-center gap-2">
            <span className="material-symbols-outlined text-lg">floor</span>
            <label>Steps</label>
          </div>
          <input
            type="range"
            min="1"
            max="20"
            value={steps}
            onChange={(e) => setSteps(e.target.value)}
          />
          <span className="w-full text-center">{steps}</span>
        </div>
      </div>
      {user && user.address && (
        <div className="flex flex-col">
          <div className="flex items-center gap-4">
            {user.img && user.img !== "null" ? (
              <img
                src={user.img}
                alt="profile"
                style={{
                  width: "50px",
                  height: "50px",
                  objectFit: "cover",
                  borderRadius: "100%"
                }}
              />
            ) : (
              <p className="data__initial ml-2">
                {user.name ? user.name[0].toUpperCase() : ""}
              </p>
            )}
            <p className="truncate text-white">{user.name}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
